import React, { useEffect, useState, useContext } from "react";
import { CircularProgress, IconButton, Popover, Typography } from "@material-ui/core";
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';

import ConfirmationModal from "./confirmationModal";

import { removeFromBasket, submitBasket } from "../../api/deals";

import { calculateTotal } from "../../components/modules/deals/productDetails";
import AuthContext from "../../services/AuthContext";

export default function Basket({basket, open, setOpen, setBasket}) {
    const [totals, setTotals] = useState([]);
    const [loading, setLoading] = useState(false);

    const context = useContext(AuthContext);

    const removeItem = async (index) => {
        try {
            const res = await removeFromBasket(basket.id, basket.products[index].id);
            if(!res.ok) throw "Call Failed";
        } catch (error) {
            return alert("Failed to Remove Item From Basket")
        }
        const tempProducts = [...basket.products];
        tempProducts.splice(index, 1);

        const tempBasket = {...basket};
        tempBasket.products = tempProducts;

        setBasket(tempBasket);
    }

    const grandTotal = () => {
        let total = 0;
        for(let i = 0; i < totals.length; i++) total += Number(totals[i]);
        return total;
    }

    const triggerRead = async () => {
        setLoading(true);
        try {
            const tempBasket = {...basket};
            tempBasket.variation = false;
            tempBasket.customer_id = context.user.client;
            tempBasket.checkout_date = new Date();
            tempBasket.basket_id = tempBasket.id;
            const res = await submitBasket(tempBasket);
            if(!res.ok) throw "Call Failed";
            window.location.reload();
        } catch (error) {
            alert(`Failed to Checkout Basket. Please Reload and Try Again - ${error}`);
            setLoading(false);
        }
    }

    useEffect(() => {
        if(basket == undefined || basket == null) return;
        if(basket.products == null || basket.products == undefined || !Array.isArray(basket.products)) return;
        const tempTotals = [...totals];

        for(let i = 0; i < basket.products.length; i++) {
            const tempProduct = basket.products[i];
            if(tempProduct == null) continue;
            tempTotals.push(calculateTotal(tempProduct.lines, tempProduct, false));
        }

        setTotals(tempTotals);
    }, [basket]);

    if(loading) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <CircularProgress/>
            <Typography variant="h6" gutterBottom>Loading Basket...</Typography>
        </div>
    );

    if(basket == undefined || basket?.products == undefined) return (
        <Popover
            open={typeof open == "object"}
            anchorEl={open}
            onClose={() => {setOpen(false)}}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography>Basket is empty</Typography>
        </Popover>
    )

    return (
        <Popover
            open={typeof open == "object"}
            anchorEl={open}
            onClose={() => {setOpen(false)}}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div style={{ padding: "2em" }}>
                <Typography style={{ borderBottom: "2px solid black" }}variant="h5" gutterBottom>Your Basket</Typography>
                {
                    (basket?.products == null || basket?.products == undefined || basket.products.length <= 0) ? (
                        <Typography>No Products Added</Typography>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2vh" }}>
                            {
                                basket.products.map((product, index) => (
                                    <div key={index} style={{ 
                                        display: "flex", 
                                        justifyContent: "space-between", 
                                        alignItems: "flex-start", 
                                        width: "100%",
                                        gap: "2vw" 
                                    }}>
                                        <div>
                                            <Typography>Ref: {product.product_ref !== null ? product.product_ref : "Ref Not Found"}</Typography>
                                            <Typography>Name: {product.name !== null ? product.name : "Name Not Found"}</Typography>
                                            <Typography>Initial Price: {totals[index] !== null ? `£${totals[index]}` : "Price Missing"}</Typography>
                                        </div>
                                        <div>
                                            <IconButton
                                                onClick={() => {removeItem(index)}}
                                            >
                                                <RemoveShoppingCartIcon style={{ color: "red"}}/>
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }
                            <Typography variant="h6" gutterBottom><b>Grand Total:</b> £{grandTotal()}</Typography>
                        </div>
                    )
                }
                <ConfirmationModal context={context} products={basket.products} triggerRead={triggerRead}/>
            </div>
        </Popover>
    )
}
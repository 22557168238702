import React, { useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../services/AuthContext";
import config from "../config";
import { Button, Icon, IconButton } from "@material-ui/core";
import "./headerStyles.css";
import {
  getProfile,
  updateProfile,
  saveImage,
  savePassword,
  getManagersIndex,
} from "../api/profile";

import SigModal from "../components/modules/signature/sigModal";
export default function Header({ logo }) {
  const context = useContext(AuthContext);

  const ref = useRef();

  const [viewDrop, setViewDrop] = useState(false);
  const [userData, setUserData] = useState(false);

  const onhandleProfileClick = () => {
    viewDrop ? setViewDrop(false) : setViewDrop(true);
  };
  const getProfileData = async () => {
    const res = await getProfile(context.user.agent_id);
    setUserData(res.data);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (viewDrop && ref.current && !ref.current.contains(e.target)) {
        setViewDrop(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [viewDrop]);

  return (
    <>
      {viewDrop && (
        <div className="user-info-container" ref={ref}>
          <div className="header-photo-container" align="center">
            <div
              className="header-rounded-logo"
              style={{
                backgroundImage: "url(" + userData.image_url + ")",
              }}
            />
            <div className="text">{context.user.name}</div>
            <div className="text">{context.user.email}</div>
          </div>
          <div className="info-box-container">
            <Link to={{ pathname: "/profile" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setViewDrop(false);
                }}
                style={{
                  color: "white",
                  borderRadius: "10px",
                  backgroundColor: "#0564e6"
                }}
              >
                View Profile
              </Button>
            </Link>
          </div>
        </div>
      )}
      <div className="container-fluid control-header">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="control-logo">MYBE</h2>
          </div>

          <div className="col-lg-6">
            <div
              style={{
                margin: 0,
                padding: 20,
                float: "right",
                color: "white",
              }}
            >
              <b>Welcome</b>
              <b style={{ color: "rgb(220, 210, 210)", padding: "5px" }}>
                {context.user.name}
              </b>
              {/* <Link to={{ pathname: "/profile" }}>*/}
                <SigModal
                  context={context}
                />
              {
                !context.user.client && (
                  <>
                    <IconButton
                      onClick={() => {
                        onhandleProfileClick();
                      }}
                    >
                      <Icon
                        style={{
                          color: "white",
                          fontSize: "30px",
                          alignItems: "center",
                        }}
                      >
                        {"account_circle_icon"}
                      </Icon>
                    </IconButton>
                    {/*</Link>*/}
                    <Link to={{ pathname: "/profiles" }}>
                      <IconButton>
                        <Icon
                          style={{
                            color: "white",
                            fontSize: "30px",
                            alignItems: "center",
                          }}
                        >
                          {"menu_book_icon"}
                        </Icon>
                      </IconButton>
                    </Link>
                  </>
                )
              }
              <a className="logout" href={config.appURL + "/logout"}>
                <b>Logout</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

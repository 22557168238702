/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

const getContractsIndex = async () => {
  const data = await fetch(`${config.appURL}/api/contracts/documentsIndex`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const getContractsDocUrl = async (id) => {
  const data = await fetch(
    `${config.appURL}/api/contracts/documentUrl?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getContractsRelations = async (id) => {
  const data = await fetch(
    `${config.appURL}/api/contracts/documents?id=${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const signContracts = async (obj) => {
  const data = await fetch(`${config.appURL}/api/contracts/sign`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const documentsIndex = async () => {
  const data = await fetch(`${config.appURL}/api/contracts/documents/post`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.ok;

  return res;
}

const getContract = async (contractId) => {
  const data = await fetch(`${config.appURL}/api/contracts/show`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({contractId: contractId}),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.ok;

  return res;
}

export {
  getContract,
  getContractsDocUrl,
  getContractsRelations,
  getContractsIndex,
  signContracts,
  documentsIndex
};

import React, { useEffect, useState, useContext } from "react";

import { Button, CircularProgress, Typography, Badge, FormControlLabel, Switch } from "@material-ui/core";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { NavLink } from "react-router-dom";

import { getDeal, getBasket, addToBasket, updateProduct } from "../../api/deals";

import Basket from "./basket";
import Product from "../../components/modules/deals/product";
import RequestChange from "../../components/modules/deals/requestChange";
import AuthContext from "../../services/AuthContext";

import "./dealStyle.css";


const verticalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2vh"
};

export default function Deal(props) {
    const [loading, setLoading] = useState(false);
    const [basket, setBasket] = useState(false);
    const [basketOpen, setBasketOpen] = useState(false);
    const [requestChangeOpen, setRequestChangeOpen] = useState(false);
    const [deal, setDeal] = useState(false);

    const [viewMode, setViewMode] = useState(false);

    const dealId = props.match.params.id
    const context = useContext(AuthContext);

    const fetchDeal = async () => {
        setLoading(true);
        try {
            const res = await getDeal(dealId);
            if(!res?.ok || res?.data == null || res?.data == undefined) throw "Call Failed";
            setDeal(res.data);
        } catch (error) {
            alert(`Failed to Load Deal - ${error}`);
        }
        setLoading(false);
    }

    const fetchBasket = async () => {
        try {
            const res = await getBasket();
            if(!res?.ok || res?.data == null || res?.data == undefined) throw "Call Failed";
            setBasket(res.data);
        } catch (error) {
            alert(`Failed to load basket - ${error}`);
        }
    }

    const basketAdd = async (productId, product) => {
        setLoading(true);
        try {
            const res = await addToBasket(basket.id, productId);
            if(!res.ok) throw "Call Failed";
            const productUpdateRes = await updateProduct(productId, product);
            if(!productUpdateRes.ok) throw "Call Failed";
            fetchBasket();
        } catch (error) {
            alert(`Failed to Add Item to Basket - ${error}`);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchDeal();
        fetchBasket();

        if(window.localStorage.getItem("deal-view") !== null) setViewMode(Number(window.localStorage.getItem("deal-view")));
    }, []);


    if(loading || !basket) return (
        <div style={verticalStyle}>
            <CircularProgress/>
            <Typography>Loading Deal... Please Wait</Typography>
        </div>
    )

    return (
        <div className="deal-container">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", borderBottom: "2px solid black" }}>
                <div>
                    <Typography variant="h4">
                        {deal.name} - <i>{deal.ref}</i>
                    </Typography>
                    <Typography variant="h6">
                        Valid: {new Date(deal.valid_from).toLocaleDateString()} - {new Date(deal.valid_to).toLocaleDateString()}
                    </Typography>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <NavLink
                        to={"/deals"}
                    >
                        <Button startIcon={<ArrowBackIosIcon/>}>
                            Back
                        </Button>
                    </NavLink>
                    <Button style={{ color: "green"}} startIcon={
                        <Badge badgeContent={basket !== undefined && basket?.products !== undefined ? basket.products.length : null} color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                            <ShoppingBasketIcon/>
                        </Badge>
                    } 
                        onClick={(e) => {setBasketOpen(e.currentTarget)}}
                    >
                        Basket
                    </Button>
                    <Basket basket={basket} setOpen={setBasketOpen} open={basketOpen} setBasket={setBasket} context={context}/>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <FormControlLabel
                    label={ viewMode ? "List View" : "Table View" }
                    control={
                        <Switch
                            checked={Boolean(viewMode)}
                            onChange={(e, checked) => {setViewMode(checked); window.localStorage.setItem("deal-view", Number(checked));}}
                        />
                    }
                />
            </div>

            {
                deal.live_products == null || deal.live_products == undefined || typeof deal.live_products !== "object" || deal.live_products.length <= 0 ? (
                    <Typography style={{ width: "100%" }} variant="h6">
                        Sorry. No products have been added to this deal. Please select a different deal.
                    </Typography>
                ) : (
                    <>
                    <div className={viewMode ? "deal-list" : "deal-list-grid"}>
                        {
                            deal.live_products.map((product, index) => (
                                <Product 
                                    product={product} 
                                    addToBasket={basketAdd} 
                                    key={index} 
                                    viewMode={viewMode}
                                />
                            ))
                        }
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Button 
                            onClick={() => {setRequestChangeOpen(true)}}
                            startIcon={<ChangeHistoryIcon/>}
                        >
                            Request Change
                        </Button>
                    </div>
                    </>
                )
            }
            <RequestChange context={context} open={requestChangeOpen} setOpen={setRequestChangeOpen} dealName={deal.name} brokerUuid={deal.broker_uuid} />
        </div>
    )
}
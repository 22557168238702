import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../services/AuthContext";

import ChangePasswordPopup from "../../components/modules/profile/passwordPopup";
import ImageDialog from "../../components/modules/image-uploader/ImageDialog";

import ProfileForm from "../../components/modules/profile/profileForm";
import ApiLoader from "../../components/common/ApiLoader";

import {
  getProfile,
  updateProfile,
  saveImage,
  savePassword,
  getManagersIndex,
  getGroupIndex,
} from "../../api/profile";

export default function Profile(props) {
  const [data, setData] = useState(false);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [lineManagers, setLineManagers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const context = useContext(AuthContext);
  const user = context.user;

  let indexState;

  if (props.location.state) {
    indexState = props.location.state;
  }

  const handleChange = (name, value) => {
    if (name === "group_name") {
      const item = groups.find((item) => item.group_name === value);

      data["group_uuid"] = item.group_uuid;
    }
    setData({ ...data, [name]: value });
  };

  const getProfileData = async () => {
    if (typeof indexState === "undefined") {
      const res = await getProfile(user.agent_id);
      console.log("Calling user agent id");

      setData(res.data);
    } else {
      const res = await getProfile(props.match.params.id);
      setData(res.data);

      console.log("Calling route id");
    }
  };

  const getLineManagersData = async () => {
    const res = await getManagersIndex();

    setLineManagers(res.data);
  };

  console.log(data);
  const getGroupData = async () => {
    const res = await getGroupIndex();

    setGroups(res.data);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading please wait..." });
    const res = await updateProfile(data.id, data);
    setLoading({ status: true, data: "Save Success Please Wait..." });

    setTimeout(() => {
      setLoading({ status: false });
    }, 4000);
  };

  const upload = async (e) => {
    const newObj = {
      uuid: user.id,
      contact_id: user.contact,
      image_path: {
        name: e.name,
        type: e.type,
        size: e.size,
        base64: e.base64,
      },
    };

    const res = await saveImage(data.id, newObj);
  };

  useEffect(() => {
    getProfileData();
    getLineManagersData();
    getGroupData();
  }, []);

  const handlePasswordChangeClick = () => {
    setPopup(true);
  };

  const handlePasswordChangeClose = () => {
    setPopup(false);
  };

  const handlePasswordSave = async (data) => {
    const newObject = { id: context.user.id, uuid: context.user.id, password: data.new_password };

    const res = await savePassword(context.user.id, newObject);

    setPopup(false);
  };

  const handleImageCropperClose = () => {
    setOpenImageCropper(false);
  };

  if (data) {
    return (
      <>
        {popup && (
          <ChangePasswordPopup
            open={popup}
            close={handlePasswordChangeClose}
            save={handlePasswordSave}
          />
        )}

        {openImageCropper && (
          <ImageDialog
            open={openImageCropper}
            close={handleImageCropperClose}
            user={user}
          />
        )}

        <ApiLoader status={loading.status} data={loading.data} />

        <ProfileForm
          data={data}
          handleSave={handleSave}
          handleChange={handleChange}
          handlePasswordChangeClick={handlePasswordChangeClick}
          upload={upload}
          type={typeof indexState !== "undefined" ? indexState.type : ""}
          lineManagersData={lineManagers}
          groups={groups}
          setOpenImageCropper={setOpenImageCropper}
        />
      </>
    );
  } else {
    return <p>Loading</p>;
  }
}

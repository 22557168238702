// TREE TOPS PROD CONFIG
const appURLtype =
  process.env.NODE_ENV === "production"
    ? "https://treetops.home.mybe.software"
    : "http://localhost:8080";

const staging = false;

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: appURLtype,
  tenantName: "Treetops",
  staging: staging,
  supportUrl: "https://unity.support.mybe.software",
  supportAppUUID: null,
  portalAppId: "93a998d8-4c71-453b-b2e4-7cd794bcde51"
};

//Staging Config -- Comment out the correct config depending on build location
// const appURLtype =
//   process.env.NODE_ENV === "production"
//     ? "https://unity.portal.mybe.software"
//     : "http://localhost:8080";

// const staging = false;

// module.exports = {
//   // ports
//   serverPort: 8080,
//   //This app url
//   appURL: appURLtype,
//   tenantName: "unity",
//   staging: staging,
//   supportUrl: "https://unity.support.mybe.software",
//   supportAppUUID: "00f41bb9-6044-4ed1-8acf-c495aa24f055",
//   portalAppId: "7bc02a60-47b5-4ca7-afb2-a1e0bdbb610b"
// };

// Prod Config
// const appURLtype =
//   process.env.NODE_ENV === "production"
//     ? "https://unity.portal.fe.staging.mybe.software"
//     : "http://localhost:8080";

// const staging = true;

// module.exports = {
//   // ports
//   serverPort: 8080,
//   //This app url
//   appURL: appURLtype,
//   tenantName: "Unity",
//   staging: staging,
//   supportAppUUID: "d3dcb216-ee07-46a9-80c5-83739f1266a3",
//   supportUrl: "https://unity.support.fe.staging.mybe.software"
// };

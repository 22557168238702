import React, { useEffect, useState } from "react";
import "./contractStyle.css";
import { CircularProgress, FormControlLabel, Switch, Typography } from "@material-ui/core";
import { documentsIndex } from "../../api/contracts";
import Contract from "../../components/modules/contracts/contract";

export default function ContractIndex({}) {
    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewMode, setViewMode] = useState(false);

    const fetchContracts = async () => {
        setLoading(true);
        try {
            const res = await documentsIndex();
            if(!res.ok || res.data == null ) throw "Call Failed";
            setContracts(res.data);
        } catch (error) {
            alert(`Failed to Load Contracts - ${error}`);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchContracts();
        setViewMode(
            window.localStorage.getItem("contracts-view-mode") !== null ?
                window.localStorage.getItem("contracts-view-mode") !== "false" : 
                false
        );
    }, []);

    if(loading) return (
        <div style={{ 
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2vh"
        }}>
            <CircularProgress/>
            <Typography variant="h5" gutterBottom>Loading Contracts...</Typography>
        </div>
    )

    return (
        <div className="contract-container">
            <div style={{ 
                display: "flex", 
                justifyContent: "flex-end", 
                width: "100%" 
            }}>
                <FormControlLabel
                    label={viewMode ? "List-View" : "Table View"}
                    control={
                        <Switch
                            checked={Boolean(viewMode)}
                            onChange={(e, checked) => {setViewMode(checked); window.localStorage.setItem("contracts-view-mode", JSON.stringify(checked))}}
                        />
                    }
                />
            </div>
            <div className={viewMode ? "contract-list" : "contract-list-grid"}>
                {
                    contracts.map((contract, index) => (
                    <Contract 
                        contract={contract}
                        viewMode={viewMode}
                        key={index}
                    />))
                }
            </div>
            {
                (!Array.isArray(contracts) || contracts.length) <= 0 && (
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Typography variant="h5" gutterBottom>
                            No Contracts Found
                        </Typography>
                    </div>
                )
            }
        </div>
    )
}
import config from "../config";

const getBasket = async () => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/basket`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const addToBasket = async (basketId, productId) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/basket/linkProducts`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({basketId : basketId, products: [productId]}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const removeFromBasket = async (basketId, productId) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/basket/unlinkProducts`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({basketId: basketId, products: [productId]}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const submitBasket = async (payload) => {
    try {
        const data = await fetch(`${config.appURL}/api/deals/basket/checkout`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const getTerms = async (productId, product) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/terms`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                productId: productId,
                product_id: productId,
                sbu_id: product.sbu_id,
                prod_cat_id: product.prod_cat_id,
                prod_group_id: product.prod_group_id,
                prod_line_id: product.prod_line_id
            }),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const getDeals = async (page = 1) => {
    try {
        const data = await fetch(`${config.appURL}/api/deals`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({page: page}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const getDeal = async (dealId) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({dealId: dealId}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const getProduct = async (productId) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/product`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({productId: productId}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const updateProduct = async (productId, payload) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/product/update`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({ productId: productId,... payload}),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

const saveRequest = async (payload) => {
    try {
        const data = await fetch(`${config.appURL}/api/deal/request/save`, {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
        });

        const res = await data.json();
        res.ok = data.ok;
        return res;
    } catch (error) {
        return {ok: false, errors: error}
    }
}

export {
    getBasket,
    addToBasket,
    removeFromBasket,
    getTerms, 
    getDeals,
    getDeal,
    getProduct,
    updateProduct,
    saveRequest,
    submitBasket
};
const getPeriodicCodes = async () => {
    return {
        data: [
            {
                "id": 16,
                "type_id": 3,
                "name": "Annually",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 17,
                "type_id": 3,
                "name": "Quarterly",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 18,
                "type_id": 3,
                "name": "Monthly",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 19,
                "type_id": 3,
                "name": "Hourly",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 20,
                "type_id": 3,
                "name": "Single",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 21,
                "type_id": 3,
                "name": "To Contract",
                "value": null,
                "description": null,
                "active": true
            },
            {
                "id": 22,
                "type_id": 3,
                "name": "Daily",
                "value": null,
                "description": null,
                "active": true
            }
        ],
        ok: true
    }

    // implement API call
}

export { getPeriodicCodes };
import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DescriptionIcon from '@material-ui/icons/Description';
import "./contractStyle.css";
import ConfirmationModal from "../../../pages/deals/confirmationModal";

import { getLink } from "../../../api/documents";
import { getContract } from "../../../api/contracts";

export default function Contract({contract, viewMode}) {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState(false);
    const [docLink, setDocLink] = useState(false);

    const fetchDocLink = async () => {
        setLoading(true);
        try {
            const res = await getLink(contract.signed_pdf_id);
            if(res.data == undefined || res.data == null) throw "Call Failed";
            setDocLink(res.data);
        } catch (error) {
            alert("Failed to Fetch Document Link");
        }
        setLoading(false);
    }

    const fetchTerms = async () => {
        setLoading(true);
        try {
            const res = await getContract(contract.id);
            if(!res.ok) throw "Call Failed";
            setProducts(res.data.services);
        } catch (error) {
            alert(`Failed to Load Products Related to Contract - ${error}`);
        }
        setLoading(false);
    }

    if(!contract) return (
        <Typography variant="h5" gutteBottom>
            Contract Not Found
        </Typography>
    )
    
    return (
        <div 
            className={viewMode ? "contract-box-list" : "contract-box"}
        >
            <Typography variant={viewMode ? "body1" : "h5"}>
                {contract.name}
            </Typography>
            <Typography>
                <i>{contract.ref}</i>
            </Typography>
            {
                loading ? (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1vh"
                    }}>
                        <CircularProgress/>
                        <Typography variant="h6" gutterBottom>
                            Loading Document...
                        </Typography>
                    </div>
                ) : (
                    <>
                        {
                            docLink ? (
                                <Button
                                    startIcon={<OpenInNewIcon/>}
                                    onClick={() => {
                                        window.open(docLink, "_blank")
                                    }}
                                    style={{
                                        backgroundColor: "green",
                                        color: "white"
                                    }}
                                    size="small"
                                    fullWidth
                                >
                                    View Contract
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<GetAppIcon/>}
                                    onClick={() => {fetchDocLink()}}
                                    style={{
                                        backgroundColor: "orange",
                                        color: "white"
                                    }}
                                    size="small"
                                    fullWidth
                                >
                                    Request
                                </Button>
                            )
                        }
                        {
                            !products && (
                                <Button
                                    startIcon={<DescriptionIcon/>}
                                    onClick={() => {fetchTerms();}}
                                    size="small"
                                    fullWidth
                                    style={{ backgroundColor: "yellow", color: "black" }}
                                >
                                    Fetch Terms
                                </Button>
                            )
                        }

                    </>
                )
            }

            <Typography>
                Status: <span
                    style={{ color: contract.status == "Active" ? "green" : "red" }}
                ><b>{contract.status}</b></span>
            </Typography>
            <div style={{ 
                display: "flex", justifyContent: "space-between", gap: "1vw" 
            }}>
                <Typography>Start: {new Date(contract.start_date).toLocaleDateString()}</Typography>
                <Typography>End: {new Date(contract.end_date).toLocaleDateString()}</Typography>
            </div>
            {
                products && (
                    <ConfirmationModal products={products} readOnly={true} />
                )
            }
            
        </div>
    )
}
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../services/AuthContext";

import config from "../../config";

import NewUserForm from "../../components/modules/profile/NewUserForm";
import ApiLoader from "../../components/common/ApiLoader";
import {
    getGroupIndex,
    createUser
} from "../../api/profile";
import {getRoles} from "../../api/services/tenant/roles";
import { getPositions } from "../../api/services/finance/positions";

export default function ProfileCreate(props) {
    const [data, setData] = useState({});
    const [groups, setGroups] = useState([]);
    const [positions, setPositions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [applications, setApplications] = useState([]);
    const [selectedApplications, setSelectedApplications] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [loading, setLoading] = useState({ status: false, data: "" });
    const context = useContext(AuthContext);

    const getApplications = async () => {
        const apps = context.body.users[0].registrations;
    
        var app_data = [];
    
        await Promise.all(
          apps.map(async (item, key) => {
            const data = await fetch(
              `${config.appURL}/application?applicationId=${item.applicationId}`,
              {
                credentials: "include", // fetch won't send cookies unless you set credentials
              }
            );
    
            const res = await data.json();
            app_data.push(res.application);
          })
        );
    
        const filterNames = ["portal", "sandbox", "support"];
    
        const filtedArray = app_data.filter(
          (item) => !filterNames.includes(item.name)
        );
    
        setApplications(filtedArray);
      };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    }

    const handleGroupChange = (group) => {
        setData({ ...data, "user_group_uuid" : group.group_uuid, "user_group_name" : group.group_name, "group": group });
    }

    const handleRoleChange = (role) => {
        setData({ ...data, "portal_role_id": role.id, "role": role });
    }

    const handleApplicationChange = (app_uuid, checked) => {
        checked ? selectedApplications[app_uuid] = app_uuid : delete selectedApplications[app_uuid];

        setSelectedApplications(selectedApplications);
    }

    const handlePositionChange = (position_id, checked) => {
        checked ? selectedPositions[position_id] = position_id : delete selectedPositions[position_id];

        setSelectedPositions(selectedPositions);
    }

    const handleSave = async () => {
        if( !data.role )
        {
            alert( "You Must Select a Role" );
            return;
        }

        if( !data.user_group_uuid )
        {
            alert( "You Must Select a Department" );
            return;
        }

        if( !data.user_email )
        {
            alert( "You Must Provide an Email Address" );
            return;
        }

        if( !data.user_fullName )
        {
            alert( "You Must Provide a Fullname" );
            return;
        }

        const payload = data;
        payload["apps"] = Object.keys(selectedApplications);
        payload["positions"] = Object.keys(selectedPositions);
        payload["portal_role_id"] = data.role.id;

        setLoading({ status: true, data: "Registering User, Please Wait. This May Take up to 1 Minute..." });
        const res = await createUser(payload);
        if( res.message )
        {
            setLoading({ status: true, data: `Registration Failed... Processing Error` });
            alert(res.message.status + " | " + res.message.message);
            setTimeout(() => {
                setLoading({ status: false });
            }, 4000);
        }
        else
        {
            setLoading({ status: true, data: "Registration Success, Please Wait..." });
            setTimeout(() => {
                window.history.go(-1);
                setLoading({ status: false });
            }, 4000);
        }
    }

    const getGroupsData = async () => {
        const res = await getGroupIndex();
    
        setGroups(res.data);
    };

    const getRolesData = async () => {
        const res = await getRoles();

        setRoles(res.data);
    }

    const getPositionsData = async() => {
        const res = await getPositions();

        setPositions(res.data);
    }

    useEffect(() => {
        getGroupsData();
        getRolesData();
        getApplications();
        getPositionsData();
    }, []);

    if( groups !== {} )
    {
        return (
            <>
                <ApiLoader status={loading.status} data={loading.data} />

                <NewUserForm
                    data={data}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    handleGroupChange={handleGroupChange}
                    handleRoleChange={handleRoleChange}
                    handleApplicationChange={handleApplicationChange}
                    handlePositionChange={handlePositionChange}
                    groups={groups}
                    applications={applications}
                    selectedApplications={selectedApplications}
                    positions={positions}
                    selectedPositions={selectedPositions}
                    roles={roles}
                />
            </>
        )
    }
    else
    {
        return (
            <p>Loading...</p>
        )
    }
}
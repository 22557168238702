import * as React from "react"

const ContactsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{``}</style>
    <circle cx={404.9} cy={810.9} r={142.9} />
    <path d="M404.9 966.3c-85.7 0-155.4-69.7-155.4-155.4s69.7-155.4 155.4-155.4 155.4 69.7 155.4 155.4-69.7 155.4-155.4 155.4zm0-285.8c-71.9 0-130.4 58.5-130.4 130.4S333 941.3 404.9 941.3s130.4-58.5 130.4-130.4-58.5-130.4-130.4-130.4z" />
    <path d="M626.7 1209.4H192.1v-82.1c0-92.2 74.8-167 167-167h100.6c92.2 0 167 74.8 167 167v82.1z" />
    <path d="M639.2 1221.9H179.6v-94.6c0-99 80.5-179.5 179.5-179.5h100.6c99 0 179.5 80.5 179.5 179.5v94.6zm-434.6-25h409.6v-69.6c0-85.2-69.3-154.5-154.5-154.5H359.1c-85.2 0-154.5 69.3-154.5 154.5v69.6z" />
    <circle cx={1366.1} cy={813.4} r={142.9} />
    <path d="M1366.1 968.8c-85.7 0-155.4-69.7-155.4-155.4S1280.4 658 1366.1 658s155.4 69.7 155.4 155.4-69.8 155.4-155.4 155.4zm0-285.8c-71.9 0-130.4 58.5-130.4 130.4s58.5 130.4 130.4 130.4 130.4-58.5 130.4-130.4S1438 683 1366.1 683z" />
    <path d="M1587.9 1212h-434.6v-82.1c0-92.2 74.8-167 167-167h100.6c92.2 0 167 74.8 167 167v82.1z" />
    <path d="M1600.4 1224.5h-459.6v-94.6c0-99 80.5-179.5 179.5-179.5h100.6c99 0 179.5 80.5 179.5 179.5v94.6zm-434.6-25h409.6v-69.6c0-85.2-69.3-154.5-154.5-154.5h-100.6c-85.2 0-154.5 69.3-154.5 154.5v69.6z" />
    <circle cx={883.2} cy={697.6} r={215.1} />
    <path d="M883.2 925.2c-60.8 0-117.9-23.7-160.9-66.7s-66.7-100.1-66.7-160.9 23.7-117.9 66.7-160.9S822.4 470 883.2 470c60.8 0 117.9 23.7 160.9 66.7s66.7 100.1 66.7 160.9-23.7 117.9-66.7 160.9-100.1 66.7-160.9 66.7zm0-430.2c-111.7 0-202.6 90.9-202.6 202.6s90.9 202.6 202.6 202.6 202.6-90.9 202.6-202.6S994.9 495 883.2 495z" />
    <path d="M1170.1 1310H609.9c-32.8 0-59.5-26.7-59.5-59.5v-101.8c0-63.8 24.8-123.7 69.9-168.8 45.1-45.1 105-69.9 168.8-69.9h201.7c63.8 0 123.7 24.8 168.8 69.9 45.1 45.1 69.9 105 69.9 168.8v101.8c.1 32.8-26.6 59.5-59.4 59.5zM789.2 935c-117.8 0-213.7 95.9-213.7 213.7v101.8c0 19 15.5 34.5 34.5 34.5h560.2c19 0 34.5-15.5 34.5-34.5v-101.8c0-117.8-95.9-213.7-213.7-213.7H789.2z" />
  </svg>
)

export { ContactsIcon }

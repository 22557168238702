import React, { useState, useEffect, useContext } from "react";

import { getTerms } from "../../api/deals";
import { getLink } from "../../api/documents";

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CircularProgress, Typography, Button, Dialog, DialogTitle, DialogContent, Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import AuthContext from "../../services/AuthContext";

const verticalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2vh"
};

export default function ConfirmationModal({products, triggerRead = () => {}, readOnly = false}) {
    const [open, setOpen] = useState(false);
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const productLength = products?.length ? products.length : 0;
    const context = useContext(AuthContext);

    const getProductTerm = async (product) => {
        let term = false;
        try {
            const res = await getTerms(product.product_id, product);
            if(!res?.ok || res?.data == undefined) throw "Call Failed";
            term = res.data;
        } catch (error) {
            return alert("Failed to Pull Document ID " + error);
        }

        const temp = [...terms];

        for(let i = 0; i < term.length; i++) {
            temp.push({
                title: term[i].document_title,
                docId: term[i].document_id,
                name: product.name
            });
        }
        setTerms(temp);
    }

    const loadTerms = async () => {
        for(let i = 0; i < productLength; i++) {
            setLoading(i + 1);
            await getProductTerm(products[i]);
        }
        setLoading(false);
    }

    const getTermLink = async (docId) => {
        try {
            const res = await getLink(docId);
            if(res?.data == undefined || res?.data == null) throw "Call Failed";
            return res.data;
        } catch (error) {
            return alert("Failed to Generate Document Link " + error);
        }
    }

    const displayTerm = async (docId) => {
        const link = await getTermLink(docId);
        if(link == undefined || link == null) return;
        window.open(link, "_blank");
    }

    const handleReadChange = (index, checked) => {
        const temp = [...terms];
        if( temp[index] == undefined || temp[index] == null ) return;
        let tempItem = {...temp[index]};

        tempItem.read = checked;
        if(tempItem.alert) delete tempItem.alert;
        temp[index] = tempItem;
        setTerms(temp);
    }

    const readAll = () => {
        const temp = [...terms];
        for(let i = 0; i < temp.length; i++) {
            let tempItem = {...temp[i]};
            tempItem.read = true;
            temp[i] = tempItem;
        }
        setTerms(temp);
    }
    
    const triggerAccept = () => {
        const temp = [...terms];
        let fail = false;
        if(context.user.signature_id == null) return setError("You must set your digital signature before you are able to submit an order. Please attach your signature via the form in the top-right of portal.");

        for(let i = 0; i < temp.length; i++) {
            if(temp[i].read) continue;

            fail = true;
            let tempItem = {...temp[i]};
            tempItem.alert = true;
            temp[i] = tempItem;
        }

        if(fail) { setTerms(temp); return alert("You have not accepted all of the terms and conditions.")}
        triggerRead(); 
        setOpen(false);
    }

    useEffect(() => {
        if(productLength <= 0) return setLoading(false);
        loadTerms();
    }, []);

    if(error) return (
        <div style={verticalStyle}>
            <CancelIcon style={{ color: "red" }}/>
            <Typography variant="h5">{error}</Typography>
        </div>
    )

    if(productLength == 0) return (
        <div style={verticalStyle}>
            <HourglassEmptyIcon/>
        </div>
    )

    if(loading) return (
        <div style={verticalStyle}>
            <CircularProgress/>
            <Typography>
                Loading Terms... {loading} / {productLength}
            </Typography>
        </div>
    )

    return (
        <>
            <Button
                onClick={() => {setOpen(true)}}
                style={{ backgroundColor: "green", color: "white"}}
                fullWidth
                startIcon={<ReceiptIcon/>}
                variant="contained"
                color="primary"
            >
                View Terms and Conditions
            </Button>
            <Dialog
                onClose={() => {setOpen(false)}}
                open={open}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle
                    style={{ backgroundColor: "rgb(77, 208, 225)", color: "white" }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Typography variant="h5" gutterBottom>
                            {readOnly ? 
                                "Attached Terms and Conditions" :
                                "Please Confirm These Terms and Conditions"
                            }
                        </Typography>
                        <IconButton
                            onClick={() => {setOpen(false)}}
                            style={{ color: "white" }}
                        ><CloseIcon/></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {
                        terms.map((item, index) => (
                            <div 
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "2vw",
                                    backgroundColor: item.alert ? "rgba(250, 30, 30, 0.5)" : null,
                                    color: item.alert ? "white" : null,
                                    padding: "5px",
                                    borderRadius: "10px",
                                    margin: "5px"
                                }}
                                key={index}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {item.name !== null ? item.name : "Product Name Not Found"} : {item.title !== null ? item.title : "Term Title Not Found"}
                                </Typography>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                                    <Button
                                        variant="contained"
                                        startIcon={<VisibilityIcon/>}
                                        onClick={() => {displayTerm(item.docId)}}
                                        style={{ marginRight: "2vw", backgroundColor: "rgb(77, 208, 225)", color: "white" }}
                                    >
                                        View Terms
                                    </Button>
                                    {
                                        !readOnly && (
                                            <FormControlLabel
                                                label="Accept"
                                                control={
                                                    <Checkbox
                                                        icon={<CloseIcon fontSize="small"/>}
                                                        checkedIcon={<DoneIcon fontSize="small" style={{ color: "green" }}/>}
                                                        checked={item.read !== undefined && item.read}
                                                        onChange={(e, checked) => {handleReadChange(index, checked)}}
                                                    />
                                                }
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        ))
                    }
                    {
                        !readOnly && (
                            <div
                                style={{ 
                                    display: "flex", 
                                    justifyContent: "space-between",
                                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                                    padding: "1vw",
                                    backgroundColor: "rgb(77, 208, 225)",
                                    borderRadius: "5px",
                                    gap: "2vw"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "red", color: "white" }}
                                    fullWidth
                                    onClick={() => {readAll()}}
                                    startIcon={<DoneAllIcon/>}
                                >
                                    I Agree to All of the Terms
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "green", color: "white" }}
                                    startIcon={<DoneIcon/>}
                                    onClick={() => {triggerAccept();}}
                                >
                                    Accept and Sign Contract
                                </Button>
                            </div>
                        )
                    }


                </DialogContent>
            </Dialog>
        </>
    )
}